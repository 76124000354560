import request from './request'

//上传附件
export const uoloader_request = (file,url) => {
    const formData = new FormData()
    formData.append("file", file)
    return request({
        method:'POST',
        url:url,
        data: formData
    })
}