<template>
    <van-sticky>
        <van-nav-bar :left-arrow="navbar.left" :left-text="left_text" @click-left="back">
            <template #title>
                <van-icon :name="title_icon" />
                <span style="color:#1989fa;">{{title_name}}</span>
            </template>
            <template #right v-if="navbar.right.type">
                <span v-if="navbar.right.type==='text'" style="color:#1989fa;" @click="$emit('show_pp')">{{navbar.right.name}}</span>
                <van-icon v-if="navbar.right.type==='icon'" :name="navbar.right.name" />
            </template>
        </van-nav-bar>
    </van-sticky>
</template>

<script>
export default {
    name:'',
    data(){
        return {}
    },
    computed:{
        left_text() {
            return this.navbar.left ? '返回' : ''
        }
    },
    methods:{
        back() {
            this.$router.go(-1);//返回上一层
        }
    },
    filters:{},
    props:{
        navbar: {
            type: Object,
            default() {
                return {
                    left: false,
                    right: {
                        type: '',
                        name: ''
                    }
                }
            }
        },
        title_name: {
            type: String,
            default() {
                return ''
            }
        },
        title_icon: {
            type: String,
            default() {
                return ''
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style>
.van-nav-bar {
    margin-bottom: 0.5rem;
}
</style>